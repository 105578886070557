import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async findByType(type) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.batchProcess.Controller.findByType(type))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchByTypeAndStatus(type, status) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.batchProcess.Controller.findByTypeAndStatus(type, status))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.batchProcess.Controller.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
