<template>
  <div>
    <div class="master-detail-caption">
      Invoice Details
    </div>
    <dx-data-grid
      :data-source="dataSource"
      :show-borders="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :column-auto-width="true"
    >
      <dx-column data-field="costGroupName" caption="Cost Group" />
      <dx-column data-field="itemGroupName" caption="Charge Item" />
      <dx-column data-field="quantity" />
      <dx-column data-field="chargeRate" alignment="right" cell-template="chargeRateTemplate" />
      <dx-column data-field="totalCost" alignment="right" cell-template="totalCostTemplate" />
      <template #chargeRateTemplate="{ data }">
        {{ getCurrencyFormat(data) }}
      </template>
      <template #totalCostTemplate="{ data }">
        {{ getCurrencyFormat(data) }}
      </template>
    </dx-data-grid>
  </div>
</template>
<script>
import invoiceItemService from '@/http/requests/finance/invoiceItemService'
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid'
import { currencyFormatter } from '@core/utils/filter'

export default {
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const dataItems = this.detailData.data
    const invoiceId = dataItems.id
    this.getInvoiceItems(invoiceId)
    return {
      dataSource: [],
      invoiceId: invoiceId,
    }
  },
  methods: {
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    getInvoiceItems(invoiceId) {
      return invoiceItemService
        .fetchAllByInvoiceId(invoiceId)
        .then(response => {
          this.dataSource = [...response]
        })
    },
  },
}
</script>
<style>
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 20px 20px 15px 15px !important;
}
.master-detail-caption {
    padding: 0 0 10px 10px;
    font-size: 20px;
    font-weight: bold;
}
</style>
