<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="invoicePreviewGrid"
          ref="invoicePreviewGridRef"
          key-expr="id"
          :height="setHeight"
          :data-source="dataSource"
          :no-data-text="noDataText"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @toolbar-preparing="onToolbarPreparing($event)"
          @selection-changed="onSelectionChanged"
        >
          <dx-load-panel :enable="true" />
          <dx-column data-field="companyName" />
          <dx-column data-field="accountNo" caption="Acc #" />
          <dx-column data-field="suiteNo" caption="Suite #" />
          <dx-column data-field="storeName" caption="Store" />
          <dx-column data-field="invoiceNo" caption="Invoice #" />
          <dx-column data-field="invoiceAmount" caption="Total" cell-template="amountTemplate" />
          <dx-column data-field="totalDue" caption="Due" cell-template="amountTemplate" />
          <dx-column type="buttons" caption="Generate">
            <dx-button
              text="Generate"
              css-class="btn btn-sm btn-success"
              :on-click="generateSingleInvoice"
            />
          </dx-column>
          <template #amountTemplate="{ data }">
            {{ getCurrencyFormat(data.value) }}
          </template>
          <dx-master-detail :enabled="true" template="masterDetailTemplate" />
          <template #masterDetailTemplate="{ data: detail }">
            <invoice-details :detail-data="detail" />
          </template>
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="allPages"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="getInvoicePreviewByAccount"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                />
              </div>
              <div class="text-sm-left text-center text-nowrap mr-1">
                Status:
                <span class="badge" :class="`badge-${batchProcess.statusInfo.variant}`">
                  {{ batchProcess.statusInfo.text }}
                </span>
              </div>
              <div class="text-sm-left text-warning text-center text-nowrap">
                Last Generation Time: {{ formatedDate(batchProcess.creationTime) }}
              </div>
              <div class="text-sm-left text-center text-success text-nowrap ml-2">
                Preview Total: {{ getCurrencyFormat(invoicePreviewTotal) }}
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import invoiceService from '@/http/requests/finance/invoiceService'
import batchProcessService from '@/http/requests/system/batchProcessService'
import shipService from '@/http/requests/ship/shipService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { currencyFormatter, formatDateTime } from '@core/utils/filter'
import { Notify } from '@robustshell/utils/index'
import InvoiceDetails from './InvoiceDetails.vue'

export default {
  components: {
    'invoice-details': InvoiceDetails,
  },
  mixins: [GridBase],
  data() {
    return {
      accountNo: '',
      warehouses: [],
      selectedWarehouse: 0,
      dataSource: [],
      batchProcess: {
        id: null,
        creationTime: null,
        processType: '',
        status: '',
        description: '',
        statusInfo: {
          variant: '',
          text: '',
          status: '',
        },
      },
      invoicePreviewTotal: 0,
      noDataText: 'No data. System is already preparing invoice preview. Please check back later.',
      isDisabledSelectedGenerate: true,
    }
  },
  mounted() {
    this.getWarehouses()
    this.getInvoicePreviewBatch()
    this.calculatePreviewTotal()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Generate Invoices',
          disabled: this.isDisabledSelectedGenerate,
          type: 'success',
          width: '200',
          icon: 'preferences',
          onClick: () => {
            this.generateMultipleInvoice()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Check Status',
          type: 'default',
          width: '200',
          icon: 'clock',
          onClick: () => {
            this.getInvoicePreviewBatch()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Regenerate Invoices',
          type: 'default',
          width: '200',
          icon: 'pulldown',
          onClick: () => {
            this.createAsyncBatchProcess()
          },
        },
        location: 'after',
      })
    },
    onSelectionChanged(e) {
      this.isDisabledSelectedGenerate = !(e.selectedRowKeys.length > 0)
      const dataGrid = this.$refs.invoicePreviewGridRef.instance
      dataGrid.repaint()
    },
    formatedDate(date) {
      return formatDateTime(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    getFormated(status) {
      if (status === 'RUNNING') return { variant: 'warning', text: 'Running', status: 'RUNNING' }
      if (status === 'PAUSED') return { variant: 'secondary', text: 'Paused', status: 'PAUSED' }
      if (status === 'COMPLETED') return { variant: 'success', text: 'Ready', status: 'COMPLETED' }
      return { variant: 'danger', text: 'Cancelled' }
    },
    async getWarehouses() {
      const result = await shipService.fetchWarehouses()
      const data = result.data
      this.warehouses.splice(0, this.warehouses.length)
      data.forEach(element => {
        if (element.name.toLowerCase() !== 'common') {
          this.warehouses.push({ value: element.id, text: element.name })
        }
      })
      this.selectedWarehouse = this.warehouses[0].value
    },
    getInvoicePreviewByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo
      this.fetchInvoicesByFilter()
    },
    getSearchFilter() {
      return {
        accountNo: this.accountNo,
        companyId: '',
        storeId: '',
        warehouseId: this.selectedWarehouse,
        status: 'all',
        beginDate: null,
        endDate: null,
        preview: true,
      }
    },
    fetchInvoicesByFilter() {
      invoiceService
        .fetchAllByQuery(this.getSearchFilter(), 'page=0&size=500')
        .then(result1 => {
          const { data } = result1
          this.dataSource = data.content
        })
    },
    calculatePreviewTotal() {
      invoiceService.calculatePreviewTotal().then(result => {
        this.invoicePreviewTotal = result
      })
    },
    getInvoicePreviewBatch(e) {
      batchProcessService.findByType('INVOICE_PREVIEW').then(result => {
        this.batchProcess = { ...result.data }
        this.batchProcess.statusInfo = { ...this.getFormated(this.batchProcess.status) }
        const status = this.batchProcess.status
        return status
      }).then(result => {
        if (result === 'COMPLETED') {
          this.fetchInvoicesByFilter()
          this.calculatePreviewTotal()
        } else {
          Notify.warning('System is already preparing invoice preview. Please check back later.')
        }
      })
    },
    createAsyncBatchProcess() {
      if (!this.selectedWarehouse) {
        Notify.error('Please select a warehouse')
        return
      }
      if (this.batchProcess.status === 'RUNNING') {
        Notify.warning('System is already preparing invoice preview. Please check back later.')
        return
      }
      this.batchProcess.status = 'RUNNING'
      const batchProcess = {
        processType: 'INVOICE_PREVIEW',
        status: 'RUNNING',
        description: 'Invoice preview generation process',
      }
      batchProcessService.create(batchProcess).then(result => {
        this.batchProcess = result.data
        this.batchProcess.statusInfo = this.getFormated(this.batchProcess.status)
        this.dataSource = []
        const accountNo = this.accountNo ? this.accountNo : 0
        invoiceService.dryRunInvoicesForCompaniesByWarehouse(this.selectedWarehouse, accountNo).then(() => {
          this.getInvoicePreviewBatch()
        })
      })
    },
    generateSingleInvoice(e) {
      this.$swal({
        title: 'Are you sure you want to generate this invoice?',
        text: 'This action will generate and actual invoice. You can do certain edits in the actual invoice.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Generate',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const item = { ...e.row.data }
          const generateInvoices = [
            {
              warehouseId: item.warehouseId,
              companyId: item.companyId,
              storeId: item.storeId,
              previewInvoiceId: item.id,
              dryRun: false,
            },
          ]
          this.generateInvoice(generateInvoices)
        }
      })
    },
    generateMultipleInvoice() {
      this.$swal({
        title: 'Are you sure you want to generate all selected invoices?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Generate',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const dataGrid = this.$refs.invoicePreviewGridRef.instance
          const selecedRows = dataGrid.getSelectedRowsData()
          const generateInvoices = []
          selecedRows.forEach(item => {
            const invoice = {
              warehouseId: item.warehouseId,
              companyId: item.companyId,
              storeId: item.storeId,
              previewInvoiceId: item.id,
              dryRun: false,
            }
            generateInvoices.push(invoice)
          })
          this.isDisabledSelectedGenerate = true
          this.generateInvoice(generateInvoices)
        }
      })
    },
    generateInvoice(generateInvoices) {
      if (this.selectedWarehouse === 0) {
        Notify.danger('Please select a Warehouse.')
        return
      }
      invoiceService.generateInvoices(generateInvoices)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Invoice Generated!',
            text: 'A new invoice has been generated',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .then(() => {
          this.getInvoicePreviewBatch()
        })
    },
  },
}
</script>

<style lang="scss">
.actionClass {
  width: 120px;
}
</style>
